<template lang="pug">
.brand-wrapper
  .container-fluid.position-relative
    .row.mb-5
      .col-12.d-flex.justify-content-start
        om-heading(h1) Themekit check page
    template(v-for="theme in themes")
      .row.mb-2
        .col-12.d-flex.justify-content-start
          om-heading(h2) {{ theme.name }} ({{ getTemplates(theme).length }})
        .col-12 Custom themekit: {{ JSON.stringify(getThemeKit(theme)) }}
      .row(:ref="paletteRef")
        .col-3(v-for="template in theme.templates" style="margin-bottom: 1.5rem")
          template-frame.cursor-pointer(
            @observable="addObservable($event.$el)"
            allowSsr
            @inited="updateDimensions"
            :dimensions="boxDimensions"
            :template="template"
            endpoint="themekit-preview"
            :payload="{ themeKit: getThemeKit(theme), baseTheme: true }"
            @contentLoaded="onContentLoaded"
          )
          om-button.mt-2(primary :to="getTemplateEditorLink(template)") Open in template editor
  portal(to="root")
    template-preview
</template>

<script>
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
  import { mapState } from 'vuex';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import paletteMixin from '@/mixins/palette';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
  import TemplatePreview from '@/components/Modals/TemplatePreview';

  export default {
    components: { TemplateFrame, TemplatePreview },
    mixins: [paletteMixin, previewParentMixin, ssrMixin, observableCollectionMixin],
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (!vm.account || vm.account.databaseId !== 44) vm.$router.replace('/templates');
      });
    },
    data: () => ({
      mainColor: '#97DB4F',
      themeKitBase: {
        name: 'Custom check kit',
        rounding: 'large',
        fonts: ['open-sans', 'open-sans'],
      },
    }),
    apollo: {
      themes: {
        query: GET_CUSTOM_THEMES,
        update(data) {
          return data.themes.base.filter(({ themeKit }) => !!themeKit?.colors?.mainColor);
        },
      },
    },
    computed: {
      ...mapState(['account']),
      customPalette() {
        return getPaletteColors(this.mainColor);
      },
      themeColors() {
        const colors = ['#79C99E', '#508484', '#4D5359', '#4A4238'];
        return [...Array(4)].map((_, index) => ({ name: index, themeColor: colors[index] }));
      },
    },
    methods: {
      getTemplates({ templates }) {
        return templates || [];
      },
      getThemeKit(source) {
        const [mainColor, ...secondaryColors] = this.customPalette;
        return {
          ...this.themeKitBase,
          sourceTheme: source._id,
          colors: {
            mainColor,
            secondaryColors,
            themeColors: this.themeColors,
          },
        };
      },
      getTemplateEditorLink(template) {
        return {
          name: 'template_editor',
          params: {
            userId: 44,
            baseTemplate: template.name,
          },
        };
      },
    },
  };
</script>
